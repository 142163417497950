import { useVisitStore } from '@/store/visit';
import { useVisitConditionsStore } from '@/store/visit-conditions';
import { RouteName } from '@/router/route-name';
import router from '@/router';
import { translate } from '@/plugins/translator';
import type { ITranslateableText } from '@einfachgast/shared';

export async function denyCheckin (reason?: ITranslateableText, createVisit?: boolean): Promise<void> {
  const visitConditionsStore = useVisitConditionsStore();
  const visitStore = useVisitStore();

  // Make denied visitCheckIn
  if (createVisit) {
    void visitStore.checkin('denied', reason).catch(() => { /*this exception is swallowed on purpose*/ });
  }

  if (reason) {
    await router.replace({ name: RouteName.CheckinDenied, params: { reason: window.btoa(translate(reason))}});
  } else {
    await router.replace({ name: RouteName.CheckinDenied });
  }

  visitConditionsStore.clear();
  visitStore.setCheckinDenied(true);
}